export const csrfToken = () => {
    window.addEventListener('load', function () {

        var formsCollection = document.getElementsByTagName("form");

        for(var i=0;i<formsCollection.length;i++) {
            let formT = formsCollection[i];

            if (formT.dataset.hasOwnProperty('freeform') ) {

                const formHandle = formT.dataset['handle'];


                fetch(`/dynamic/form-tokens/${formHandle}`)
                    .then(result => result.json())
                    .then(result => {
                        console.log('result', result);
                        console.log('hash', result.hash);
                        console.log('csrf', result.csrf);

                        // Update the Form Hash
                        formT.querySelector('input[name=formHash]').value = result.hash;

                        // Update the JS Honeypot (ONLY when JS Enhancement setting is ON)
                        let honeypot = result.honeypot;
                        let honeypotInput = formT.querySelector('input[name^="freeform_form_handle"]');

                        if (honeypotInput !== null && honeypot !== null) {
                            honeypotInput.setAttribute('id', honeypot.name);
                            honeypotInput.setAttribute('name', honeypot.name);
                            honeypotInput.value = honeypot.hash;
                        }

                        // Locate and update the CSRF input
                        let csrf = result.csrf;
                        formT.querySelector('input[name=' + csrf.name + ']').value = csrf.value;
                    });
            }

        }
    });
};
