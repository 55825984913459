import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { csrfToken } from './components/csrf-token';
import { navigation } from './components/navigation'
import { copy } from './components/copy'
import { modals } from './components/modals'

UIkit.use(Icons);
navigation();
csrfToken();
copy();
modals();