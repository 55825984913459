export const navigation = () => {
    window.addEventListener('load', function () {
        const el = document.querySelectorAll(".hasSubnav > a, a.back");

        if (0 === el.length) {
            return;
        }

        for (let i = 0; i < el.length; i++) {
            el[i].addEventListener('click', function (e) {
                e.preventDefault();

                const subGroup = el[i].closest('.navItem').querySelector('.subGroup');
                subGroup.classList.add('active')

                this.classList.add('subOpen')
                for (let j = 0; j < el.length; j++) {
                    if (e.currentTarget !== el[j] || e.currentTarget.classList.contains('subOpen') ) {
                        document.querySelector('.navItems').classList.add('active')
                    }
                    if (e.currentTarget.classList.contains('subOpen')) {
                        e.target.returnValue = true;
                        if ( e.target.closest('a').classList.contains('back') || e.target.classList.contains('backText')) {
                            document.querySelector('.navItems').classList.remove('active');
                            e.target.closest('.subGroup').classList.remove('active')
                            e.target.closest('.navItem').classList.remove('subOpen')

                            return false;
                        }
                    }

                }

            });
        }
    });
}
