import UIkit from 'uikit';
export const modals = async () => {
    window.addEventListener('load', function () {
        const modal = document.getElementsByClassName("modal");

        if (0 === modal.length) {
            return;
        }
        
        for (let i = 0; i < modal.length; i++) {
            modal[i].onclick = function() { 
                //console.log(modal[i].querySelector('iframe').getAttribute('data-src'));
                var src = this.querySelector('iframe').getAttribute('data-src');
                //console.log(src);
               this.querySelector('iframe').setAttribute('src', src);
            }
        }

            UIkit.util.on('.modal', 'hide', function (e) { 
                this.querySelector('iframe').src = ''
                window.dispatchEvent(new Event('resize'));
            });
            UIkit.util.on('.modal', 'show', function (e) {
                var src = this.querySelector('iframe').src;
                this.querySelector('iframe').setAttribute('src', src);
            });
    });
    
}
